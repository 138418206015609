html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  color:#0c2b83;
}

#root {
  height: 100%;    
} 

.material-icons.validation {
  color: #AFCD54;
}

.all-caps input { 
  text-transform: uppercase;
}
::-webkit-input-placeholder { /* WebKit browsers */
  text-transform: none;
}
::-ms-input-placeholder { /* Recent browsers */
  text-transform: none;
}
::placeholder { /* Recent browsers */
  text-transform: none;
}

@media only screen and (min-width: 600px) {
  .form-container {
    max-width: 30rem;
    margin: 0 auto;
    padding-bottom: 2rem !important;
    background-color: white;
  }  

  .form-container h3 {
    line-height: 2rem;
  }

  .form-container input {
    border: 1px solid #00aae5;
  }
}

@-webkit-keyframes lms-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes lms-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

